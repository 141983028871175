<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">
            {{ mode ? "Staff Management Detail" : "Create Staff Management" }}
          </h1>
        </div>
        <div class="title-panel mt-3">User Info</div>
        <b-container class="no-gutters bg-white">
          <div class="pt-3">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="First name"
                  placeholder="First name"
                  type="text"
                  ref="firstName"
                  name="first_name"
                  isRequired
                  :isValidate="$v.form.first_name.$error"
                  :v="$v.form.first_name"
                  v-model="form.first_name"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Last Name"
                  placeholder="Last Name"
                  type="text"
                  ref="lastName"
                  name="last_name"
                  isRequired
                  :isValidate="$v.form.last_name.$error"
                  :v="$v.form.last_name"
                  v-model="form.last_name"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Staff ID"
                  placeholder="Staff ID"
                  type="text"
                  ref="staffID"
                  name="Staff_ID"
                  :isValidate="$v.form.sales_no.$error"
                  :v="$v.form.sales_no"
                  isRequired
                  @onKeypress="handleStaff"
                  v-model.trim="form.sales_no"
                />
              </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="title-panel mt-3">Branch</div>
        <b-container class="no-gutters bg-white">
          <b-row class="justify-content-between align-items-center mt-3">
            <b-col lg="5" md="6" sm="6" xs="10" cols="10">
              <b-input-group>
                <b-form-input
                  type="search"
                  class="search-bar"
                  @keyup.enter="handleSearch($event)"
                  @input="handleSearch($event)"
                  placeholder="Search Branch, Plant ID"
                  v-model="filter.search"
                >
                </b-form-input>
                <b-input-group-append is-text>
                  <b-iconstack font-scale="2" type="submit">
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                    ></b-icon>
                  </b-iconstack>
                </b-input-group-append> </b-input-group
            ></b-col>
            <b-col xl="2" lg="3" md="3" sm="6" xs="2" cols="2">
              <button
                type="button"
                class="btn button btn-mobile btn-block"
                id="open-modal-branch"
                @click="openModalBranch()"
              >
                <font-awesome-icon icon="plus" class="text-black d-sm-none" />
                <span class="d-none d-sm-block">
                  <font-awesome-icon icon="plus-square" class="pointer" />
                  Add branch
                </span>
              </button>
            </b-col>
          </b-row>
          <div class="pb-3 mt-3">
            <TableStaff
              :filter="filter"
              :pageOptions="pageOptions"
              :fields="fields"
              :items="showBranch"
              :isBusy="isBusy"
              :rows="rows"
              :showingTo="showingTo"
              @filterPage="filterPage"
              @deleteBranch="deleteBranch"
            />
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.active"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.active ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction routePath="/manageStaff" @submit="saveForm()" />
      </b-form>
      <ModalSelectBranch
        ref="ModalSelectBranch"
        @selectBranch="selectBranch"
        :list="branchList"
        id="1"
      />
      <ModalSuccess
        ref="modalAlert"
        msg="บันทึกข้อมูลสำเร็จ"
        @close="closeModalAlert"
      />
      <ModalLoading ref="modalLoading" :hasClose="false" />
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import ModalSelectBranch from "@/components/staff/ModalSelectBranch";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalSuccess from "@/components/ModalSuccess";
import ModalLoading from "@/components/modal/ModalLoading";
import TableStaff from "@/components/staff/TableStaff";
export default {
  components: {
    InputText,
    ModalSuccess,
    ModalLoading,
    OtherLoading,
    TableStaff,
    ModalSelectBranch,
  },
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      sales_no: { required },
    },
  },
  computed: {
    mode() {
      return this.$route.params.id != 0;
    },
  },
  watch: {},
  data() {
    return {
      branchList: [],
      fields: [
        {
          key: "plant_id",
          label: "Plant ID",
        },
        {
          key: "short_name",
          label: "Branch Name",
        },
        {
          key: "branch_group",
          label: "Branch Group",
        },

        {
          key: "branch_type",
          label: "Type",
        },
        {
          key: "actions",
          label: "Action"
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        member_level_id: null,
        search: null,
        page: 1,
        take: 10,
        is_consent: null,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      items: [],
      isLoading: false,
      noPleaseSelect: true,
      form: {
        first_name: "",
        last_name: "",
        sales_no: "",
        active: 1,
        add_branch_id: [],
        delete_branch_id: [],
        branch_id: [],
      },
      delete_branch_id: [],
      upgradeTier: [],
      isDisable: true,

      id: this.$route.params.id,

      showBranch: [],
    };
  },
  created() {
    this.isLoading = true;
    this.getPerson();
    this.getBranchList();
  },
  methods: {
    handleSearch(val) {
      var keys = ["plant_id", "name"];
      var result = this.items.filter(function (e) {
        return keys.some(function (a) {
          return e[a].toLowerCase().includes(val.toLowerCase());
        });
      });
      this.showBranch = result.length == 0 && val == "" ? this.items : result;
    },
    handleStaff(e) {
      var charCode = e.keyCode;

      if (charCode == 32) return e.preventDefault();
    },
    selectBranch(val) {
      var branchSelect = [];
      for (let branchs of this.branchList) {
        for (let branch of branchs.branchList) {
          if (val.includes(branch.id)) {
            branch.is_check = true;
            branch.branch_group = branchs.name;
            branchSelect.push(branch);
          } else {
            branch.is_check = false;
          }
        }
      }
      var index = 0;
      for (let deleteValue of this.delete_branch_id) {
        if (val.includes(deleteValue)) this.delete_branch_id.splice(index, 1);
        index++;
      }
      const add = val.filter((el) => !this.form.branch_id.includes(el));
      const deleteID = this.form.branch_id.filter((i) => !val.includes(i));

      this.items = branchSelect;
      this.showBranch = branchSelect;
      this.form.add_branch_id = add;
      this.delete_branch_id = deleteID;
      this.rows = val.length;
    },
    async getPerson() {
      const id = this.$route.params.id;
      if (id != 0) {
        const res = await this.$Axios(
          `${process.env.VUE_APP_API}/salesperson/${id}`
        );
        this.form = { ...res.data.detail };
      }
    },
    async getBranchList() {
      const result = await this.$Axios(
        `${process.env.VUE_APP_API}/branch/group`
      );
      var branchSelect = [];
      for (let branch of result.data.detail) {
        branch.selected_count = 0;
        for (const check of branch.branchList) {
          if (this.form.branch_id.includes(check.id)) {
            branch.selected_count += 1;
            check.branch_group = branch.name;
            check.is_check = true;
            branchSelect.push(check);
          }
        }
      }
      this.rows = branchSelect.length;
      this.branchList = result.data.detail;
      this.items = branchSelect;
      this.showBranch = branchSelect;
      this.isLoading = false;
    },
    openModalBranch() {
      this.$refs.ModalSelectBranch.show();
    },
    closeModalAlert() {
      this.$refs.modalAlert.hide();
    },
    deleteBranch(id) {
      if (!this.delete_branch_id.includes(id)) {
        const index = this.items.findIndex((el) => el.id == id);
        const brachCheck = this.branchList.find((el) =>
          el.branchList.find((el) => {
            return el.id == id;
          })
        );

        const branch = brachCheck.branchList.find((el) => el.id == id);
        branch.is_check = false;
        brachCheck.selected_count -= 1;
        this.items.splice(index, 1);

        if (this.form.add_branch_id) {
          const addValue = this.form.add_branch_id.indexOf(id);
          if (addValue != -1) this.form.add_branch_id.splice(addValue, 1);
        }
        if (this.form.branch_id.includes(id)) {
          this.delete_branch_id.push(id);
        }
      }
    },
    saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.$refs.modalLoading.show();
      this.form.delete_branch_id = this.delete_branch_id;
      if (this.mode) {
        this.$Axios
          .put(`${process.env.VUE_APP_API}/salesperson`, this.form)
          .then((res) => {
            this.$refs.modalLoading.hide();
            if (res.data.result == 1) {
              this.$swal2.fire(res.data.message, "", "success");
            } else {
              this.$swal2.fire("เกิดข้อผิดพลาด", res.data.message, "error");
            }
          })
          .catch((err) => {
            console.log(err, "err message");
            this.$swal2.fire(
              "เกิดข้อผิดพลาด",
              "เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
              "error"
            );
          });
      } else {
        this.$Axios
          .post(`${process.env.VUE_APP_API}/salesperson`, this.form)
          .then((res) => {
            this.$refs.modalLoading.hide();

            if (res.data.result == 1) {
              this.$swal2.fire(res.data.message, "", "success");
              this.$router.replace({
                path: "/manageStaff/detail/" + res.data.detail.id,
              });
              this.getPerson();
            } else {
              this.$swal2.fire("เกิดข้อผิดพลาด", res.data.message, "error");
            }
          })
          .catch((err) => {
            console.log(err, "err message");
            this.$swal2.fire(
              "เกิดข้อผิดพลาด",
              "เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
              "error"
            );
          });
      }
    },

    filterPage(filter) {
      this.filter = filter;
      this.getPerson();
    },
    onDeleteImage() {
      this.form.icon = "";
      this.showPreview = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
</style>
